import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`An ECDN server’s video delivery status.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">type</GQLKeyword> VideoDelivery <GQLOpen mdxType="GQLOpen" /></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Current number of users viewing a video through this server (buffering as well).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Nullable in case of errors (this metric is coming from an external service).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">currentNumberOfViewers</GQLName>: <GQLLink href="/ecdn-api-scalars/Int" mdxType="GQLLink">Int</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Current number of users experiencing buffering on this server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Nullable in case of errors (this metric is coming from an external service).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">currentNumberOfBuffering</GQLName>: <GQLLink href="/ecdn-api-scalars/Int" mdxType="GQLLink">Int</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Number of users viewing a video through this server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Nullable in case of errors (this metric is coming from an external service).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">numberOfViewers</GQLName>: [<GQLLink href="/ecdn-api-objects/ScalarPoint" mdxType="GQLLink">ScalarPoint</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]</GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Number of users experiencing buffering on this server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Nullable in case of errors (this metric is coming from an external service).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">numberOfBuffering</GQLName>: [<GQLLink href="/ecdn-api-objects/ScalarPoint" mdxType="GQLLink">ScalarPoint</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]</GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
      <GQLLine mdxType="GQLLine"><GQLClose mdxType="GQLClose" /></GQLLine>
    </GQLCodeSnippet>
    <h2>{`Fields`}</h2>
    <h3>{`currentNumberOfViewers: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Int"
      }}>{`Int`}</a></h3>
    <p>{`Current number of users viewing a video through this server (buffering as well).
Nullable in case of errors (this metric is coming from an external service).`}</p>
    <h3>{`currentNumberOfBuffering: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Int"
      }}>{`Int`}</a></h3>
    <p>{`Current number of users experiencing buffering on this server.
Nullable in case of errors (this metric is coming from an external service).`}</p>
    <h3>{`numberOfViewers: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/ScalarPoint"
      }}>{`ScalarPoint`}</a>{`!]`}</h3>
    <p>{`Number of users viewing a video through this server.
Nullable in case of errors (this metric is coming from an external service).`}</p>
    <h3>{`numberOfBuffering: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/ScalarPoint"
      }}>{`ScalarPoint`}</a>{`!]`}</h3>
    <p>{`Number of users experiencing buffering on this server.
Nullable in case of errors (this metric is coming from an external service).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      